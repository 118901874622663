import React from 'react';
import PropTypes from 'prop-types';
import GlobalLayout from '@components/layouts/GlobalLayout';
import { withTranslation, Trans } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

const Error = ({ t }) => (
  <GlobalLayout pageId="issue" pageTitle={t('common:page-404-title')}>
    <div className="issue-body">
      <div className="wrapper">
        <div className="page-header">{t('common:page-404-title')}</div>
        <div className="page-body">
          <Trans i18nKey="common:page-404-desc">
            <a
              href={`mailto:${process.env.NEXT_PUBLIC_MINDX_SUPPORT_EMAIL}`}
              title={t('common:page-404-lbl-email')}
            >
              email us
            </a>
            .
          </Trans>
        </div>
        <div className="page-footer">
          <a
            href="/"
            className="primary"
            title={t('common:page-404-lbl-go-home')}
          >
            {t('common:page-404-lbl-go-home')}
          </a>
        </div>
      </div>
    </div>
    <style jsx>
      {`
        @import './src/sass/_vars.scss';
        @import './src/sass/_mixins.scss';
        .issue-body {
          padding-top: 80px;
          padding-bottom: 160px;
        }

        .wrapper {
          background: url('#{$image_url}/404_illustration@2x.png') top center
            no-repeat;
          background-size: 360px 258px;
        }

        .page-header {
          padding-top: 258px;
          @include font(23px, 700, -0.6px, 36px, #2b2c2d);
        }

        .page-body {
          margin-top: 16px;
          @include font(16px, 400, -0.4px, 26px, #2b2c2d);

          a {
            font-weight: 600;
            color: #18aa78;
          }
        }

        .page-footer {
          margin-top: 40px;
          a {
            display: inline-block;
            padding: 0 28px;
            text-align: center;
            color: #ffffff;
            background: #18aa78;
            height: 56px;
            @include font(18px, 700, -0.4px, 56px, #ffffff);
            border-radius: 2px;
            @media (hover: hover) {
              &:hover {
                background-color: #228765;
              }
            }
            &:active {
              background-color: #228765;
            }
          }
        }

        @media all and (min-width: 768px) {
          .issue-body {
            padding: 172px 0 120px 0;
          }

          .wrapper {
            padding: 0 0 0 32px;
            background-position: right bottom;
            background-size: 428px 306px;
          }

          .page-header {
            padding-top: 24px;
            font-size: 32px;
            line-height: 42px;
            letter-spacing: -1px;
          }

          .page-body {
            max-width: 411px;
          }

          .page-footer {
            padding-bottom: 76px;
          }
        }

        @media all and (min-width: $resolution_d_min) {
          .issue-body {
            padding: 100px 0 98px 0;
          }

          .wrapper {
            padding: 0 167px;
            background-position: top right 167px;
            background-size: 560px 400px;
          }

          .page-header {
            padding-top: 96px;
          }

          .page-footer {
            padding-bottom: 98px;
          }
        }
      `}
    </style>
  </GlobalLayout>
);

Error.propTypes = {
  t: PropTypes.func.isRequired,
};

export const getStaticProps = async ({ req, res, locale }) => ({
  props: {
    ...(await serverSideTranslations(locale, ['common'])),
    notFound: true,
  },
});

export default withTranslation('common')(Error);
